import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import footerLogo from '../../static/footer-logo.svg'

const currentYear = () => {
    const d = new Date()

    return d.getFullYear()
}

const Footer = () => {
    return (
        <FooterEl>
            <Logo src={footerLogo} alt="VideoElephant logo" />
            <Links>
                <Link to="/about">About VideoElephant</Link>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/terms">Terms and Conditions</Link>
            </Links>
            <Copyright>Copyright VideoElephant, {currentYear()}</Copyright>
        </FooterEl>
    )
}

const FooterEl = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    flex-flow: row nowrap;
    background-color: rgb(10, 10, 10);
    height: 44px;
    font-size: 12px;
    /* exceed Ads player z-index */
    z-index: 2;

    @media screen and (max-width: 672px) {
        padding: 0 16px;
        height: 88px;
        flex-flow: row wrap;
    }
`

const Logo = styled.img`
    opacity: 0.5;
    width: 33px;
    height: 20px;
    margin: 0;
    @media screen and (max-width: 672px) {
        order: 1;
        flex-grow: 1;
        width: 40px;
        max-width: 40px;
        height: 24px;
    }
`

const Links = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;

    a {
        font-size: 12px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 16px;
        padding: 0 30px;
        opacity: 0.5;
        color: white;
        text-decoration: none;
        transition: opacity 0.3s ease-in-out;

        &:hover {
            transition: opacity 0.3s ease-in-out;
            opacity: 1;
        }
    }
    @media screen and (max-width: 672px) {
        order: 3;
        justify-content: space-between;

        a {
            padding: 0;
        }
    }
`

const Copyright = styled.p`
    font-size: 12px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 16px;
    color: white;
    opacity: 0.3;
    margin: 0;
    @media screen and (max-width: 672px) {
        order: 2;
        flex-grow: 1;
        text-align: right;
    }
`

export default Footer
