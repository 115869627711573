import styled from '@emotion/styled'
import React from 'react'

const HamburgerBtn = ({ isOpen, onClickHandler }) => {
    return (
        <MenuIcon>
            <div
                id="menu-icon"
                tabIndex="0"
                role="button"
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                className={isOpen ? 'open' : undefined}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </MenuIcon>
    )
}

const MenuIcon = styled.div`
    #menu-icon {
        display: none;
        width: 30px;
        height: 30px;
        z-index: 1000;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;
        margin: 12px;
        user-select: none;

        &:focus {
            outline: none;
        }

        @media screen and (max-width: 672px) {
            display: flex;
        }

        span {
            display: block;
            position: absolute;
            user-select: none;
            height: 4px;
            width: 100%;
            background: white;
            border-radius: 4px;
            opacity: 1;
            transform: rotate(0deg);
            transition: 0.2s ease-in-out;

            @media screen and (max-width: 672px) {
                height: 3px;
                border-radius: 3px;
            }
        }

        span:nth-of-type(1) {
            top: 0px;
            right: 0;
            width: 15px;
            transform-origin: center right;
        }

        span:nth-of-type(2) {
            top: 10px;
            width: 103%;

            @media screen and (max-width: 672px) {
                top: 8px;
            }
        }

        span:nth-of-type(3) {
            top: 20px;
            left: 0;
            width: 15px;
            transform-origin: center left;

            @media screen and (max-width: 672px) {
                top: 16px;
            }
        }

        &.open span:nth-of-type(1) {
            transform: rotate(-45deg);
            top: -2px;
            right: 6px;

            @media screen and (max-width: 672px) {
                top: -1px;
                right: 4px;
            }
        }

        &.open span:nth-of-type(2) {
            transform: rotate(45deg);
            top: 10px;

            @media screen and (max-width: 672px) {
            }
        }

        &.open span:nth-of-type(3) {
            transform: rotate(-45deg);
            top: 26px;
            left: 6px;

            @media screen and (max-width: 672px) {
                top: 21px;
                left: 4px;
            }
        }
    }
`

export default HamburgerBtn
