import Cookies from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import DismissButton from './dismiss-btn'
import { loadScriptAsync } from '../utils'
import './email-capture-modal.css'

Modal.setAppElement('#___gatsby')

function getModalOptions(setShowModal, setFormClosedCookie) {
    return {
        shouldCloseOnOverlayClick: true,
        onRequestClose: () => {
            setShowModal(false)
        },
        onAfterClose: () => {
            // set essential cookie to track subscription form close status regardless of coookie consent
            setFormClosedCookie()
            document.querySelector('#___gatsby').style.opacity = 1
        },
        onAfterOpen: () => {
            document.querySelector('#___gatsby').style.opacity = 0.4
        },
        className: 'custom-modal-content',
        overlayClassName: 'custom-modal-overlay',
    }
}

function createHubspotForm(onFormSubmitted) {
    if (typeof window !== 'undefined' && window.hbspt) {
        const form = window.hbspt.forms.create({
            portalId: '2235516',
            formId: '878bb6db-27b2-485c-8627-fca1a94a8b77',
            target: '#hbspt-form-wrapper',
            css: '',
            onFormSubmitted,
        })

        return form
    }
}

function insertFormIntoModalWhenReady(form, setShowModal, formInsertedRef) {
    form.onReady(() => {
        if (!formInsertedRef.current) {
            setShowModal(true)
            const fWrapper = document.querySelector('#hbspt-form-wrapper')
            const modal = document.querySelector('#subscription-modal')

            if (modal && fWrapper) {
                modal.append(fWrapper)
                formInsertedRef.current = true
            }
        }
    })
}

function setFormClosedCookie() {
    Cookies.set('subscriptionform_status', 'closed')
}

function isFormClosedCookieSet() {
    return Cookies.get('subscriptionform_status') === 'closed'
}

function hasCookieConsentModalClosed() {
    const consentStatus = Cookies.get('cookieconsent_status')

    return ['allow', 'deny'].includes(consentStatus)
}

function getOnFormSubmittedFn(setShowModal) {
    return () => {
        setShowModal(false)
    }
}

const EmailCaptureModal = () => {
    const [showModal, setShowModal] = useState(false)
    const formInsertedRef = useRef(false)
    // allowing initial value to be undefined so that rerender is triggered on consent status change
    const cookieConsent = useSelector(state => state.cookieConsentStatus)

    useEffect(() => {
        if (!isFormClosedCookieSet()) {
            // only show the email capture modal after the cookie consent modal has been displayed and closed
            if (cookieConsent || hasCookieConsentModalClosed()) {
                loadScriptAsync('//js.hsforms.net/forms/v2.js').then(() => {
                    const onFormSubmitted = getOnFormSubmittedFn(setShowModal)
                    const form = createHubspotForm(onFormSubmitted)

                    if (form) {
                        insertFormIntoModalWhenReady(form, setShowModal, formInsertedRef)
                    }
                })
            }
        }
    }, [cookieConsent, formInsertedRef])

    // using a div instead of a React Fragment here due to error when appending Hubspot form to modal
    return (
        <div>
            <Helmet>
                <link href="//js.hsforms.net/forms/v2.js" as="script" rel="preload"></link>
            </Helmet>
            <div style={{ display: showModal ? 'flex' : 'none' }} id="hbspt-form-wrapper"></div>
            <Modal isOpen={showModal} {...getModalOptions(setShowModal, setFormClosedCookie)} id="subscription-modal">
                <DismissButton
                    customClass="modal-dismiss-btn"
                    onClickHandler={() => setShowModal(false)}
                    imgAltTxt="close subscription modal"
                />
            </Modal>
        </div>
    )
}

export default EmailCaptureModal
