import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import HamburgerBtn from '../components/hamburger-btn'
import searchIcon from '../../static/icon-search.svg'

const toggleMobileDropdownMenu = (isMenuOpen, setMenuOpen) => {
    if (!isMenuOpen) {
        document.querySelector('html').classList.add('mobile-menu-open')
    } else {
        document.querySelector('html').classList.remove('mobile-menu-open')
    }
    setMenuOpen(!isMenuOpen)
}

const Header = ({ currentPath = '' }) => {
    const [isMenuOpen, setMenuOpen] = useState(false)

    return (
        <HeaderEl className={`${isMenuOpen && 'menu-open'}`}>
            <HeaderTop>
                <Link
                    to="/"
                    style={{
                        color: `white`,
                        textDecoration: `none`,
                    }}
                >
                    <Logo src="/logo.svg" alt="VideoElephant logo" />
                </Link>
                <HeaderBtn className="mobile-search-btn">
                    <Link to="/search">
                        <img className="search-icon" src={searchIcon} alt="search icon" />
                    </Link>
                </HeaderBtn>
                <HamburgerBtn
                    isOpen={isMenuOpen}
                    onClickHandler={() => toggleMobileDropdownMenu(isMenuOpen, setMenuOpen)}
                />
            </HeaderTop>
            <ButtonsGroup className={`menu-list ${!isMenuOpen && 'hide-menu'}`}>
                <MobileMenuTitle className={!isMenuOpen && 'hidden'}>Menu</MobileMenuTitle>
                <HeaderBtn className={currentPath === '/' && 'highlight'}>
                    <h2>
                        <Link to="/">Channel Guide</Link>
                    </h2>
                </HeaderBtn>
                <HeaderBtn className={currentPath.includes('/discover') && 'highlight'}>
                    <h2>
                        <Link to="/discover">Discover</Link>
                    </h2>
                </HeaderBtn>
                <HeaderBtn className={currentPath.includes('/news') && 'highlight'}>
                    <h2>
                        <Link to="/news">News</Link>
                    </h2>
                </HeaderBtn>
            </ButtonsGroup>
            <HeaderBtn className={`desktop-search-btn ${currentPath.includes('/search') && 'highlight'}`}>
                <Link to="/search">
                    <img className="search-icon" src={searchIcon} alt="search icon" />
                </Link>
            </HeaderBtn>
        </HeaderEl>
    )
}

const MobileMenuTitle = styled.p`
    @media screen and (min-width: 672px) {
        display: none;
    }
    @media screen and (max-width: 672px) {
        opacity: 0.7;
        color: rgb(255, 255, 255);
        font-size: 11px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.96px;
        line-height: 16px;
    }
`

const Logo = styled.img`
    width: 60px;
    height: 36px;
    @media screen and (max-width: 672px) {
        width: 33px;
        height: 20px;
    }
`

const ButtonsGroup = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;

    @media screen and (max-width: 672px) {
        &.menu-list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items: center;

            a {
                width: 288px;
                color: rgb(255, 255, 255);
                font-size: 28px;
                font-family: 'Source Sans Pro', sans-serif;
                font-weight: 600;
                opacity: 0.5;
                text-align: center;
                letter-spacing: 0.16px;
                line-height: 36px;
                text-decoration: none;
            }

            &.hide-menu {
                display: none;
            }
        }
    }
`

const HeaderEl = styled.header`
    position: absolute;
    width: 100%;
    height: 72px;
    background: rgba(33, 33, 33, 0.95);
    z-index: 5;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;

    .desktop-search-btn {
        padding-right: 30px;

        &:not(.highlight) {
            margin-bottom: -5px;
        }
    }

    @media screen and (max-width: 672px) {
        flex-flow: column nowrap;
        height: 48px;

        &.menu-open {
            position: fixed;
            height: 100vh;
            width: 100%;
            z-index: 99;
            background-color: #141414;

            > div {
                background-color: #141414;
            }
        }

        .desktop-search-btn {
            display: none;
        }
    }
`

const HeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 75px;

    & > a {
        margin: 0;
        margin-top: 20px;
        margin-left: 48px;
    }

    @media screen and (min-width: 672px) {
        div.mobile-search-btn {
            display: none;
        }
    }

    @media screen and (max-width: 672px) {
        flex-flow: row nowrap;
        width: 100%;
        justify-content: flex-end;

        & > a {
            flex-grow: 1;
            margin: 0;
            margin-left: 16px;
            margin-top: 12px;
        }
    }
`

const HeaderBtn = styled.div`
    position: relative;
    padding: 0px 24px 0px 24px;

    h1,
    h2,
    a {
        margin: 0;
        color: white;
    }

    h2 {
        display: flex;
        justify-content: center;
        flex-flow: column;
    }

    @media screen and (min-width: 672px) {
        &.highlight {
            background: linear-gradient(-180deg, rgb(26, 26, 26) 0%, rgba(20, 20, 20, 0.95) 100%);
            border-radius: 0px;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 4px;
                background: linear-gradient(90deg, rgb(28, 102, 147) 0%, rgb(85, 157, 212) 100%);
                border-radius: 0px;
            }
        }
        a {
            opacity: 1;
            text-decoration: none;
            color: rgb(255, 255, 255);
            font-size: 14px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 20px;
            padding: 26px 0;
            text-transform: uppercase;
            transition: opacity 0.3s ease-in-out;

            &:hover {
                transition: opacity 0.3s ease-in-out;
                opacity: 1;
            }
        }

        .search-icon {
            width: 100%;
            height: 94%;
            margin: 0;
            padding: 12px 17px 16px 17px;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 672px) {
        margin: 0;

        a {
            display: inline-block;
        }

        &.mobile-search-btn {
            padding: 10px 12px 12px 12px;
            margin: 0;

            img {
                margin: 0;
            }
        }

        .search-icon {
            width: unset;
            height: 25px;
        }
    }
`

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
