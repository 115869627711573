import styled from '@emotion/styled'
import React from 'react'
import DismissIcon from '../../static/icon-search-clear.svg'

const DismissButton = ({ onClickHandler, imgAltTxt, customClass }) => {
    return (
        <DismissBtn onClick={onClickHandler} className={customClass}>
            <img src={DismissIcon} alt={imgAltTxt} />
        </DismissBtn>
    )
}

const DismissBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    max-width: 32px;
    max-height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: transparent;
    padding: 0;
    margin-right: 48px;
    margin-bottom: 30px;
    transition: border 0.3s ease-in-out;

    img {
        max-width: 28px;
        width: 100%;
        height: 100%;
        max-height: 28px;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.5);
        transition: border 0.3s ease-in-out;
    }

    @media screen and (max-width: 672px) {
        max-width: 25px;
        max-height: 25px;
        margin-right: 16px;
        margin-bottom: 12px;
    }
`

export default DismissButton
